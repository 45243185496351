import {plainToClass} from 'class-transformer';

import {IdName} from '@models/id-names/_id-names';
import {PostType} from '@models/constants/post-types';

export const POST_CATEGORY_IDS = {
  STRATEGY: 1,
  BASKETBALL: 2,
  PHYSICAL: 3,
  COACHING: 4,
};

export class PostCategory extends IdName {
  slug: string;
  // Placeholder to shove cached list of PostTypes this PostCategory can be associated with.
  postTypes: PostType[] = [];
}

export const POST_CATEGORIES = {};
POST_CATEGORIES[POST_CATEGORY_IDS.STRATEGY] = plainToClass(PostCategory, {id: POST_CATEGORY_IDS.STRATEGY, name: 'Strategy', slug: 'strategy', ordinal: 0});
POST_CATEGORIES[POST_CATEGORY_IDS.BASKETBALL] = plainToClass(PostCategory, {id: POST_CATEGORY_IDS.BASKETBALL, name: 'Evaluation', slug: 'evaluation', ordinal: 2});
POST_CATEGORIES[POST_CATEGORY_IDS.PHYSICAL] = plainToClass(PostCategory, {id: POST_CATEGORY_IDS.PHYSICAL, name: 'Physical', slug: 'physical', ordinal: 3});
POST_CATEGORIES[POST_CATEGORY_IDS.COACHING] = plainToClass(PostCategory, {id: POST_CATEGORY_IDS.COACHING, name: 'Coaching', slug: 'coaching', ordinal: 4});

// TODO Replace key with constant
export const PostTypesToPostCategories = {
  1: {required: [POST_CATEGORIES[POST_CATEGORY_IDS.BASKETBALL]], additional: false, remove: []},
  2: {required: [], additional: true, remove: []},
  3: {required: [], additional: true, remove: [POST_CATEGORIES[POST_CATEGORY_IDS.COACHING]]},
  4: {required: [POST_CATEGORIES[POST_CATEGORY_IDS.PHYSICAL]], additional: false, remove: []},
  5: {required: [], additional: true, remove: [POST_CATEGORIES[POST_CATEGORY_IDS.COACHING], POST_CATEGORIES[POST_CATEGORY_IDS.BASKETBALL]]},
  6: {required: [POST_CATEGORIES[POST_CATEGORY_IDS.STRATEGY]], additional: false, remove: []},
  7: {required: [POST_CATEGORIES[POST_CATEGORY_IDS.STRATEGY]], additional: false, remove: []},
  8: {required: [], additional: false, remove: []},
  9: {required: [], additional: true, remove: [POST_CATEGORIES[POST_CATEGORY_IDS.STRATEGY], POST_CATEGORIES[POST_CATEGORY_IDS.BASKETBALL]]},
  10: {required: [], additional: true, remove: [POST_CATEGORIES[POST_CATEGORY_IDS.COACHING]]},
  11: {required: [POST_CATEGORIES[POST_CATEGORY_IDS.STRATEGY]], additional: false, remove: []},
  12: {required: [POST_CATEGORIES[POST_CATEGORY_IDS.BASKETBALL]], additional: false, remove: []},
};

export const PostCategoriesToPostTypes = {
  1: [2, 5, 6, 10, 11],
  2: [9, 1, 2, 10, 12],
  3: [9, 4, 2, 8, 10],
  4: [2, 9],
};
