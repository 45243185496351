import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {NavigationStart, Router} from '@angular/router';
import {untilDestroyed, UntilDestroy} from '@ngneat/until-destroy';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {RootStoreState} from 'apps/_store';
import {Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
import * as actions from '@store/video-store/actions';
import {VideoNavigationTypes, VideoTabTypes} from '@models/video';
import {VideoStoreSelectors} from '@store/video-store';
import {filter, take} from 'rxjs/operators';
import {VideoService} from '@services/video.service';

@UntilDestroy()
@Component({
  selector: 'bild-video-root',
  templateUrl: './video-root.component.html',
  styleUrls: ['./video-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('animateBrowse', [
      state('false', style({
        left: '150%',
      })),
      state('true', style({
        left: '0',
      })),
      transition('false => true', animate('300ms linear', keyframes([
        style({ left: '150%', offset: 0 }),
        style({ left: '75%', offset: 0.50 }),
        style({ left: '0', offset: 1 })
      ]))),
      transition('true => false', animate('300ms linear', keyframes([
        style({ left: '0', offset: 0 }),
        style({ left: '75%', offset: 0.50 }),
        style({ left: '150%', offset: 1 })
      ])))
    ]),
  ],
})
export class VideoRootComponent implements OnInit, OnDestroy {
  readonly tab = VideoTabTypes;
  isMobile: boolean;
  currentTab: VideoTabTypes = VideoTabTypes.BROWSE_VIDEO;
  showPanel: boolean = false;
  currentRootUrl: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: any,
    protected actions$: Actions,
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected dialogRef: MatDialogRef<VideoRootComponent>,
    protected router: Router,
    protected store$: Store<RootStoreState.State>,
    protected title: Title,
    protected videoService: VideoService
  ) {
  }

  ngOnInit() {
    this.title.setTitle('Video');

    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall, Breakpoints.Small
    ]);

    layoutChanges.pipe(untilDestroyed(this)).subscribe(result => {
      this.isMobile = result.matches;
    });

    this.store$.pipe(
      select(VideoStoreSelectors.selectSession),
      take(1),
      untilDestroyed(this)
    ).subscribe(session => {
      this.setTabFromNavigation(session.currentNavigation);
    });

    this.videoService.selectedTabSubject.pipe(
      untilDestroyed(this),
    ).subscribe((currentTab) => {
      this.currentTab = currentTab;
    });

    this.videoService.videoDialogOpenedSubject.pipe(
      untilDestroyed(this),
    ).subscribe((videoDialogOpened) => {
      if (videoDialogOpened == false) {
        this.closeDialog(true);
      }
    });

    this.router.events.pipe(
      untilDestroyed(this),
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      let url = event.url.split('?')[0];
      if (this.currentRootUrl && this.currentRootUrl != url) {
        this.closeDialog(false);
      }
      else if (!this.currentRootUrl) {
        this.currentRootUrl = url;
      }
    });
  }

  setTabFromNavigation(currentNavigation) {
    if (this.router.url.includes('metrics')) {
      currentNavigation = VideoNavigationTypes.EXPLORE;
    }
    switch (currentNavigation) {
      case VideoNavigationTypes.EXPLORE:
        this.currentTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.QUEUE:
        this.currentTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.PLAYLIST_1:
        this.currentTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.PLAYLIST_2:
        this.currentTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.PLAYLIST_3:
        this.currentTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.PLAYLIST_4:
        this.currentTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.PLAYLIST_5:
        this.currentTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.PLAYLIST_6:
        this.currentTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.GENERATE:
        this.currentTab = VideoTabTypes.GENERATE_PLAYLIST;
        break;
      case VideoNavigationTypes.LIVE:
        this.currentTab = VideoTabTypes.COURT_CAMS;
        break;
      case VideoNavigationTypes.UPLOAD:
        this.currentTab = VideoTabTypes.UPLOAD;
        break;
      case VideoNavigationTypes.DIAGNOSTICS:
        this.currentTab = VideoTabTypes.VIDEO_DIAGNOSTICS;
        break;
    }
    this.videoService.selectedTabSubject.next(this.currentTab);
    this.cdr.markForCheck();
  }

  ngAfterViewInit() {
    this.showPanel = true;
  }

  closeDialog(routeNavigate) {
    this.showPanel = false;
    if (routeNavigate) {
      this.router.navigate([], {
        queryParams: {
          'videoPanelOpen': null,
          'playlistID': null,
          'generatedPlaylistID': null,
        },
        queryParamsHandling: 'merge',
      });
    }

    setTimeout(() => {
      this.dialogRef.close();
    }, 300);
  }

  ngOnDestroy() {
  }
}
