import {NavOption} from '@models/nav-option';

export class TeamPostFeedNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Post Feed';
  shouldHide= false;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id];
    this.link = ['', 'bild', 'entities', 'teams', team.id];
  }
}

export class TeamHighlightsNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Highlights';
  shouldHide= false;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id, 'highlighting'];
    this.link = ['', 'bild', 'entities', 'teams', team.id, 'highlighting'];
  }
}

export class TeamRosterNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Roster';
  shouldHide;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id, 'roster'];
    this.link = ['', 'bild', 'entities', 'teams', team.id, 'roster'];
    this.shouldHide = team.hideLOCs;
  }
}

export class TeamDevelopmentNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Player Development';
  shouldHide = false;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id, 'player-development'];
    this.link = ['', 'bild', 'entities', 'teams', team.id, 'player-development'];
  }
}

export class TeamEvaluationNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Evaluation';
  shouldHide = false;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id, 'evaluation'];
    this.link = ['', 'bild', 'entities', 'teams', team.id, 'evaluation'];
  }
}

export class TeamMetricsNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Metrics';
  shouldHide = false;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id, 'metrics'];
    this.link = ['', 'bild', 'entities', 'teams', team.id, 'metrics', 'measures'];
  }
}

export class TeamEventsNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Events';
  shouldHide = false;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id, 'events'];
    this.link = ['', 'bild', 'entities', 'teams', team.id, 'events'];
  }
}

export class TeamPhysicalNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Physical';
  shouldHide = false;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id, 'physical'];
    this.link = ['', 'bild', 'entities', 'teams', team.id, 'physical'];
  }
}

export class TeamScoreboardNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Scoreboard';
  shouldHide = false;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id, 'scoreboard'];
    this.link = ['', 'bild', 'entities', 'teams', team.id, 'scoreboard'];
  }
}

export class TeamCommunityBoardNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Community Board';
  shouldHide = false;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id, 'community-board'];
    this.link = ['', 'bild', 'entities', 'teams', team.id, 'community-board'];
  }
}

export class TeamPracticeBoardNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Practice Board';
  shouldHide = false;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id, 'practice-board'];
    this.link = ['', 'bild', 'entities', 'teams', team.id, 'practice-board'];
  }
}

export class TeamAffiliationsNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Affiliations';
  shouldHide = false;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id, 'affiliations'];
    this.link = ['', 'bild', 'entities', 'teams', team.id, 'affiliations'];
  }
}

export class TeamOfficiatingNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Officiating';
  shouldHide = false;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id, 'officiating'];
    this.link = ['', 'bild', 'entities', 'teams', team.id, 'officiating'];
  }
}

export class TeamInformationNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Team Information';
  shouldHide = false;
  constructor(team) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'teams', team.id, 'information'];
    this.link = ['', 'bild', 'entities', 'teams', team.id, 'information'];
  }
}
