export enum VideoSubject {
  ASSISTS = 'assists',
  ASSIST_OPP ='assist_opp',
  ASSISTS_TURNOVERS = 'assists_and_turnovers',
  ASSISTS_ASSIST_OPS_TURNOVERS = 'assists_and_assist_ops_and_turnovers',
  BLOCKS = 'blocks',
  BLOCKS_STEALS = 'blocks_and_steals',
  CHANCES = 'chances',
  CHARTING = 'charting',
  CUTS = 'cuts',
  DRIVES = 'drives',
  FOULS = 'fouls',
  FREE_THROWS = 'free_throws',
  HANDOFFS = 'handoffs',
  ISOLATION = 'isolation',
  PERIODS = 'periods',
  PICKS = 'picks',
  POINTS = 'points',
  POSSESSIONS_ASSISTS = 'possessions_and_assists',
  POST_UPS = 'post_ups',
  REBOUNDS = 'rebounds',
  SHOTS = 'shots',
  SPOT_UPS = 'spot_ups',
  STEALS = 'steals',
  TOUCHES = 'touches',
  TRANSITION = 'transition',
  TRANSITION_OFFENSE = 'transition_offense',
  TURNOVERS = 'turnovers',
  USAGE = 'usage',
  DEFENSE = 'defense',
  DEFENSE_LOC = 'defense_loc',
  DEFENSE_ACTION = 'defense_action',
  OFFENSE = 'offense',
}

export const actionNames = [
  VideoSubject.CUTS,
  VideoSubject.DRIVES,
  VideoSubject.HANDOFFS,
  VideoSubject.ISOLATION,
  VideoSubject.PICKS,
  VideoSubject.POST_UPS,
  VideoSubject.SPOT_UPS,
  VideoSubject.TRANSITION,
];

export const videoSubjectToLabel = (subject: string) => {
  switch (subject) {
    case VideoSubject.CHANCES:
      return 'Minutes';
    case VideoSubject.FREE_THROWS:
      return 'Free Throws';
    case VideoSubject.ASSISTS_TURNOVERS:
      return 'Assists + Turnovers';
    case VideoSubject.ASSISTS_ASSIST_OPS_TURNOVERS:
      return 'Assists + Potential Assists + Turnovers';
    case VideoSubject.BLOCKS_STEALS:
      return 'Blocks + Steals';
    case VideoSubject.POSSESSIONS_ASSISTS:
      return 'Possessions + Assists';
    case VideoSubject.POST_UPS:
      return 'Post Ups';
    case VideoSubject.SPOT_UPS:
      return 'Spot Ups';
    case VideoSubject.TRANSITION_OFFENSE:
      return 'Transition Offense';
    case VideoSubject.DEFENSE:
      return 'Defense';
    case VideoSubject.OFFENSE:
      return 'Offense';
    case VideoSubject.ASSIST_OPP:
      return 'Potential Assists';
    default:
      return subject;
  }
};

export const videoSubjectToPrimaryPlayerLabel = (subject: string) => {
  switch (subject) {
    case VideoSubject.SHOTS:
      return 'Shooter';
    case VideoSubject.FREE_THROWS:
      return 'Shooter';
    case VideoSubject.ASSISTS:
      return 'Assister';
    case VideoSubject.ASSIST_OPP:
      return 'Potential Assister';
    case VideoSubject.REBOUNDS:
      return 'Rebounder';
    case VideoSubject.STEALS:
      return 'Stealer';
    case VideoSubject.BLOCKS:
      return 'Blocker';
    case VideoSubject.TOUCHES:
      return 'Player';
    case VideoSubject.TURNOVERS:
      return 'Turnover Player';
    case VideoSubject.FOULS:
      return 'Fouler';
    case VideoSubject.CHARTING:
      return 'Player';
    case VideoSubject.CUTS:
      return 'Player';
    case VideoSubject.DRIVES:
      return 'Player';
    case VideoSubject.HANDOFFS:
      return 'Player';
    case VideoSubject.ISOLATION:
      return 'Player';
    case VideoSubject.PICKS:
      return 'Player';
    case VideoSubject.POINTS:
      return 'Player';
    case VideoSubject.ASSISTS_TURNOVERS:
      return 'Player';
    case VideoSubject.ASSISTS_ASSIST_OPS_TURNOVERS:
      return 'Player';
    case VideoSubject.BLOCKS_STEALS:
      return 'Player';
    case VideoSubject.POSSESSIONS_ASSISTS:
      return 'Player';
    case VideoSubject.POST_UPS:
      return 'Player';
    case VideoSubject.SPOT_UPS:
      return 'Player';
    case VideoSubject.TRANSITION:
      return 'Player';
    case VideoSubject.TRANSITION_OFFENSE:
      return 'Player';
    case VideoSubject.USAGE:
      return 'Player';
    default:
      return subject;
  }
};

export const videoSubjectEntityToPlayer = (subject: string) => {
  switch (subject) {
    case VideoSubject.ASSISTS_TURNOVERS:
      return 'Player';
    case VideoSubject.ASSISTS_ASSIST_OPS_TURNOVERS:
      return 'Player';
    case VideoSubject.ASSIST_OPP:
      return 'Player';
    case VideoSubject.BLOCKS_STEALS:
      return 'Player';
    case VideoSubject.CHARTING:
      return 'Player';
    case VideoSubject.CUTS:
      return 'abstractPlayer';
    case VideoSubject.DRIVES:
      return 'abstractPlayer';
    case VideoSubject.HANDOFFS:
      return 'abstractPlayer';
    case VideoSubject.ISOLATION:
      return 'abstractPlayer';
    case VideoSubject.PICKS:
      return 'abstractPlayer';
    case VideoSubject.POINTS:
      return 'Player';
    case VideoSubject.POSSESSIONS_ASSISTS:
      return 'Player';
    case VideoSubject.POST_UPS:
      return 'abstractPlayer';
    case VideoSubject.SPOT_UPS:
      return 'abstractPlayer';
    case VideoSubject.TOUCHES:
      return 'Player';
    case VideoSubject.TRANSITION:
      return 'abstractPlayer';
    case VideoSubject.TRANSITION_OFFENSE:
      return 'abstractPlayer';
    case VideoSubject.USAGE:
      return 'abstractPlayer';
    default:
      return subject + videoSubjectToPrimaryPlayerLabel(subject);
  }
};

export const videoSubjectToSecondaryPlayerLabel = (subject: string) => {
  switch (subject) {
    case VideoSubject.SHOTS:
      return 'Assister';
    case VideoSubject.ASSISTS:
      return 'Shooter';
    case VideoSubject.ASSIST_OPP:
      return 'Shooter';
    case VideoSubject.STEALS:
      return 'Turnover Player';
    case VideoSubject.BLOCKS:
      return 'Shooter';
    case VideoSubject.TURNOVERS:
      return 'Stealer';
    case VideoSubject.FOULS:
      return 'Fouled Player';
    default:
      return subject;
  }
};

export const videoSubjectToFilterName = (subject: string) => {
  switch (subject) {
    case VideoSubject.CUTS:
      return 'cutsAttributes';
    case VideoSubject.DRIVES:
      return 'drivesAttributes';
    case VideoSubject.HANDOFFS:
      return 'handoffsAttributes';
    case VideoSubject.ISOLATION:
      return 'isolationAttributes';
    case VideoSubject.PICKS:
      return 'OffBallScreenAttributes';
    case VideoSubject.POST_UPS:
      return 'postUpAttributes';
    case VideoSubject.SPOT_UPS:
      return 'spotUpAttributes';
    case VideoSubject.TRANSITION:
      return 'transitionAttributes';
    default:
      return subject;
  }
};

export const videoSubjectToColumnName = (subject: string) => {
  switch (subject) {
    case VideoSubject.CUTS:
      return ['Cut'];
    case VideoSubject.DRIVES:
      return ['Drive'];
    case VideoSubject.HANDOFFS:
      return ['Handoff'];
    case VideoSubject.ISOLATION:
      return ['Isolation'];
    case VideoSubject.PICKS:
      return ['OnBallScreen', 'OffBallScreen'];
    case VideoSubject.POST_UPS:
      return ['PostUp'];
    case VideoSubject.SPOT_UPS:
      return ['SpotUp'];
    case VideoSubject.TRANSITION:
      return ['Transition'];
    default:
      return subject;
  }
};

export const synergyActionNameMapper = (action: string) => {
  switch (action) {
    case 'HandOff':
      return 'Handoff';
    case 'ISO':
      return 'Isolation';
    case 'OffScreen':
      return 'OffBallScreen';
    case 'PandRBallHandler':
      return 'OnBallScreen';
    case 'PandRRollMan':
      return 'OnBallScreen';
    case 'Post_Up':
      return 'PostUp';
    case 'Spot_Up':
      return 'SpotUp';
    default:
      return action;
  }
};

export const videoActionToGroupName = (action: string) => {
  switch (action) {
    case 'Cut':
      return VideoSubject.CUTS;
    case 'Drive':
      return VideoSubject.DRIVES;
    case 'Handoff':
      return VideoSubject.HANDOFFS;
    case 'Isolation':
      return VideoSubject.ISOLATION;
    case 'OnBallScreen':
      return VideoSubject.PICKS;
    case 'OffBallScreen':
      return VideoSubject.PICKS;
    case 'PandRBallHandler':
      return VideoSubject.PICKS;
    case 'PandRRollMan':
      return VideoSubject.PICKS;
    case 'PostUp':
      return VideoSubject.POST_UPS;
    case 'SpotUp':
      return VideoSubject.SPOT_UPS;
    case 'Transition':
      return VideoSubject.TRANSITION;
    default:
      return action;
  }
};
